<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Sucursal
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-text-field :rules="reglas.nombre" v-model="datos.nombre"
                                    label="Nombre sucursal *" required outlined dense hide-details="auto"
                                    class="">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-text-field :rules="reglas.nombre" v-model="datos.codigoSucursal"
                                    label="Código sucursal *" required outlined dense hide-details="auto"
                                    class="">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-text-field :rules="reglas.gerenteSucursal" v-model="datos.gerenteSucursal"
                                    label="Gerente sucursal *" required outlined dense hide-details="auto"
                                    class="">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <SelectTipoSucursal ref="TipoSucursalRef" :clearable="true" :dense="true"
                                    :outlined="true" :icon="false" :rules="reglas.idTipoSucursal" v-model="datos.idTipoSucursal">
                                </SelectTipoSucursal>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <SelectTipoConexionSucursal ref="TipoConexionSucursalRef" :clearable="true" :dense="true"
                                    :outlined="true" :icon="false" :rules="reglas.idTipoConexionSucursal" v-model="datos.idTipoConexionSucursal">
                                </SelectTipoConexionSucursal>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-switch class="ml-4 mr-4" v-model="datos.indAplicaImpuestos" :label="`Aplica impuesto`"></v-switch>
                        <!--<v-switch class="ml-4 mr-4" v-model="datos.indPrincipal" :label="`Ind principal`"></v-switch>--> 
                            <v-switch v-model="datos.indEcommerce" :label="`Activo Ecommerce`"></v-switch> 
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3" :disabled="guardando" @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3" :loading="guardando" small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api' 
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectTipoSucursal from './SelectTipoSucursal.vue'
import SelectTipoConexionSucursal from './SelectTipoConexionSucursal.vue'
import SucursalServices from '@/api/servicios/SucursalServices'
export default {
    components: {
        SelectTipoSucursal,
        SelectTipoConexionSucursal
    },
    setup(props, context) {
       
        const guardando = ref(false)
        const form  = ref(null)
        const TipoSucursalRef = ref(null)
        const TipoConexionSucursalRef = ref(null)
        const reglas = { 
            nombre: [

                v => !!v || "Nombre es requerido"
            ],
            codigoSucursal: [

                v => !!v || "Código sucursal es requerido"
            ],
            gerenteSucursal: [

                v => !!v || "Gerente sucursal es requerido"
            ],

            idTipoSucursal: [

                v => !!v || "Tipo sucursal es requerido"
            ],
            idTipoConexionSucursal: [

                v => !!v || "Tipo Conexion sucursal es requerido"
            ]
        }
        const datosInit = {
            id: -1,
            idEmpresa: 0,
            idTipoSucursal: null,
            nombre: '', 
            codigoSucursal: '',
            gerenteSucursal: '',
            indPrincipal: false, 
            indAplicaImpuestos: true,
            indActivo: true,
            indEcommerce: false,
            idTipoConexionSucursal : 1
        }
        const datos = ref(JSON.parse(JSON.stringify(datosInit)));
        const limpiar = () => {
            datos.value = JSON.parse(JSON.stringify(datosInit));
            form.value.resetValidation();
        }
        const cargandos = ref(false) 
        const CargarEditar = (item) => {
            TipoSucursalRef.value.selectId(item.idTipoSucursal)
            datos.value = item
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })
            
        }
        const input = (id, objectType) => {


        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try { 
                    datos.value.idEmpresa = datos.value.id == -1 ? store.state.user.empresaActual.id : datos.value.idEmpresa;
                    SucursalServices.Actualizar( 
                        store.state.user.idUsuario,
                        datos.value
                      ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {
                            
                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: `+response.data.datos.mensajeError ,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }
        return { 
            reglas,
            datos,
            CargarEditar,  
            cargandos, 
            limpiar,
            Guardar,
            form ,
            guardando,
            store,
            ActualizarLista,
            TipoSucursalRef,
            TipoConexionSucursalRef
        }
    }
}

</script>